export const groupBy = <
  T extends Record<string | number, any>,
  K extends string | number,
>(
  data: T[],
  key: keyof T | ((item: T) => string | number | null),
) => {
  return data.reduce(
    (acc, item) => {
      const indexKey = typeof key === 'function' ? key(item) : item[key];

      if (indexKey === null) {
        return {
          ...acc,
        };
      }

      return {
        ...acc,
        [indexKey]: [item, ...(acc[indexKey as K] || [])],
      };
    },
    {} as Record<K, T[]>,
  );
};

export const createElement = <T extends Element>(
  tagName: string,
  args: {
    className?: string;
    src?: string;
    innerHTML?: string;
    innerText?: string;
    onError?: OnErrorEventHandlerNonNull;
    onLoad?: () => any;
    onClick?: (this: GlobalEventHandlers, ev: MouseEvent) => any;
    href?: string;
    target?: string;
    style?: string;
    key?: string;
    alt?: string;
    children?: Element[];
  },
): T => {
  const {
    className,
    src,
    innerText,
    innerHTML,
    onError,
    onLoad,
    href,
    target,
    onClick,
    style,
    key,
    alt,
    children,
  } = args;
  const newElement = document.createElement(tagName);

  if (className) {
    newElement.setAttribute('class', className);
  }

  if (innerHTML) {
    newElement.innerHTML = innerHTML;
  } else if (innerText) {
    newElement.innerText = innerText;
  } else if (children) {
    children.forEach((child) => {
      newElement.append(child);
    });
  }

  if (src) {
    (newElement as HTMLImageElement).src = src;
    (newElement as HTMLImageElement).referrerPolicy = 'no-referrer';
  }

  if (onError) {
    (newElement as HTMLImageElement).onerror = onError;
  }

  if (onLoad) {
    (newElement as HTMLImageElement).onload = onLoad;
  }

  if (onClick) {
    newElement.onclick = onClick;
  }

  if (href) {
    (newElement as HTMLAnchorElement).href = href;
    (newElement as HTMLAnchorElement).target = target || '_blank';
    (newElement as HTMLAnchorElement).referrerPolicy = 'no-referrer';
  }

  if (style) {
    (newElement as HTMLElement).setAttribute('style', style);
  }

  if (key) {
    (newElement as HTMLElement).setAttribute('key', key);
  }

  if (alt) {
    (newElement as HTMLElement).setAttribute('alt', alt);
  }

  return newElement as unknown as T;
};

export const deflate = (data: string): string => {
  // @ts-ignore
  const deflated: Uint8Array = pako.deflate(data);
  const deflatedAsUnsafeString = deflated.reduce(
    (data: string, byte: number) => data + String.fromCharCode(byte),
    '',
  );
  return btoa(deflatedAsUnsafeString);
};

export const inflate = (data: string): string => {
  const extracted = atob(data)
    .split('')
    .map((item) => item.charCodeAt(0));
  // @ts-ignore
  const inflatedArray = pako.inflate(extracted);
  const decoder = new TextDecoder();

  return decoder.decode(inflatedArray);
};

export const getElementsByClassName = <T extends Element>(
  className: string,
): T[] | null =>
  ([...document.getElementsByClassName(className)] as T[]) || null;

export const findElementByClassName = <T extends Element>(
  className: string,
): T | null => getElementsByClassName<T>(className)?.[0] || null;

export const clamp = (val: number, max: number, min: number): number =>
  Math.max(Math.min(val, max), min);

export const random = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const maxRotate = 20;
const maxOffset = 20;
export const addHoverEffect = (element: Element) => {
  let bounding: DOMRect;
  element.addEventListener('mousemove', (event: Event) => {
    bounding = element.getBoundingClientRect();
    const relativeX = clamp(
      (event as MouseEvent).clientX - bounding.x,
      bounding.width,
      0,
    );
    const relativeY = clamp(
      (event as MouseEvent).clientY - bounding.y,
      bounding.height,
      0,
    );

    const rotateXPercent = relativeX / bounding.width;
    const rotateYPercent = relativeY / bounding.height;

    const xDegrees = Math.floor(maxRotate * rotateXPercent - maxRotate / 2);
    const yDegrees = Math.floor(maxRotate * rotateYPercent - maxRotate / 2);
    const xShadowOffset = Math.floor(
      maxOffset * rotateXPercent - maxOffset / 2,
    );
    const yShadowOffset = Math.floor(
      maxOffset * rotateYPercent - maxOffset / 2,
    );

    element.animate(
      {
        transform: `rotate3d(0, 1, 0, ${xDegrees}deg) rotate3d(1, 0, 0, ${-yDegrees}deg)`,
        boxShadow: `${-xShadowOffset}px ${-yShadowOffset}px 10px 0 #0005`,
      },
      {
        fill: 'forwards',
        duration: 400,
        easing: 'linear',
      },
    );
  });

  element.addEventListener('mouseout', () => {
    element.animate(
      {
        transform: `rotate3d(0, 1, 0, 0deg) rotate3d(1, 0, 0, 0deg)`,
        boxShadow: `0px 0px 10px 0 #0009`,
      },
      {
        fill: 'forwards',
        duration: 800,
        easing: 'ease-out',
      },
    );
  });
};
