export const svgDiagrams = () => {
  const images = [...document.querySelectorAll('#mermaid')] as HTMLPreElement[];

  images.forEach((image) => {
    image.innerHTML = image.querySelector('code')?.innerHTML || '';
  });

  const script = document.createElement('script');
  script.src =
    'https://cdn.jsdelivr.net/npm/mermaid@10.4.0/dist/mermaid.min.js';
  script.async = true;
  script.defer = true;
  script.onload = async () => {};
  document.head.appendChild(script);
};
